@font-face {
  font-family: "hollow";
  src: url("./assets/Arialic\ Hollow.ttf") format("truetype");
}
* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #cac7c7;
  transition: 0.4s ease-in-out;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

.scrollArea {
  position: absolute;
  top: 90px;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}
//sudoku image
.scrollArea
  > div:nth-child(1)
  > div:nth-child(6)
  > div:nth-child(1)
  > div:nth-child(1)
  > a:nth-child(4)
  > img:nth-child(1) {
  background: whitesmoke;
  opacity: 0.7;
}

.container {
  margin: 0;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

header {
  position: fixed;
  z-index: 99;
  width: 100%;
  padding: 2vw;
  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo1 {
      display: flex;
      .logo2 {
        color: #fff;
      }
    }

    .logo {
      display: flex;
      font-weight: 900;
      letter-spacing: 2px;
      color: #171717;
      .dot {
        color: #fff;
      }
    }
    nav {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        li {
          list-style: none;
          margin: 0 3vw;
          &.btn {
            a {
              color: #fff;
              font-weight: 600;
              background: #23232a;
              padding: 0.5rem 1rem;
              border-radius: 10px;
              font-size: 1rem;
            }
          }
          a {
            text-transform: capitalize;
            text-decoration: none;
            color: #fff;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

.title {
  font-size: 5rem;
  font-family: "hollow";
  text-align: center;
  transition: 0.5s;
  width: 100%;
  margin: 0 auto;
  color: #fff;
  a {
    text-decoration: none;
    color: inherit;
  }
  span {
    display: block;
  }
  @media (max-width: 600px) {
    font-size: 2rem;
  }
}
.title:hover {
  scale: 1.05;
  transition: 0.5s;
}
.subtitle {
  font-size: 5rem;
  color: #171717;
  text-align: center;
  opacity: 0.9;
  margin: 2rem;
  span {
    display: block;
  }
  @media (max-width: 600px) {
    font-size: 2rem;
  }
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-bar-container {
  width: 100px;
  height: 32px;
  background: #272727;
}

.loading-bar {
  height: 32px;
  background: #f15946;
}

.list {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  max-width: 80%;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-transform: uppercase;
  background-color: #171717cc;
  .tech {
    margin: 0 1vw;
    font-size: 2rem;
    span {
      display: block;
    }
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
}
.description {
  max-width: 80%;
  margin: 1rem;
  padding: 1rem;
  background-color: rgb(214, 213, 213);
  h4 {
    margin: 0;
    font-size: 1.2rem;
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
  p {
    margin: 0;
    line-height: 1.5;
    font-size: 1.1rem;
    @media (max-width: 600px) {
      font-size: 2vw;
    }
  }
}
img {
  width: 50vw;
  transition: 0.5s;
  max-width: 800px;
  margin: 1rem;
}
img:hover {
  scale: 1.03;
  transition: 0.5s;
}
.arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  color: #fff;
  font-size: 1rem;
  transition: 0.3s;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
}
.arrow:hover {
  scale: 1.1;
  transition: 0.3s;
}
div.arrow:nth-child(3) > div:nth-child(4) {
  color: #171717;
  opacity: 1.8;
  background-color: #cac7c7;
}
